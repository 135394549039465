.head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(rgba(2, 2, 2, 0.8), rgba(32, 95, 3, 0.8));
}

.head h1 {
    margin: 4rem 0rem;
    color: white;
    font-size: 3rem;
}

.container {
    width: 100%;
    padding: 3rem;
    padding-top: 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.left,
.right {
    width: 50%;
}

.container .left form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.24);
    padding: 2rem;
}

.container .left form input {
    padding: 1rem;
    border: none;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.24);
    outline: none;
}

.container .left form textarea {
    padding: 1rem;
    border: none;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.24);
    outline: none;
}

.container .left form button {
    padding: 1rem;
    background-color: green;
    border: none;
    color: white;
    font-weight: 600;
}

.container .right img {
    width: 30rem;
}

@media screen and (max-width:768px) {
    .head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: linear-gradient(rgba(255, 68, 0, 0.823), rgba(0, 0, 0, 0.762));
    }

    .head h1 {
        margin: 2rem 0rem;
        color: white;
        font-size: 2rem;
    }

    .container {
        width: 100%;
        padding: 1rem;
        padding-top: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin-top: 1rem;
    }

    .left,
    .right {
        width: 100%;
    }

    .container .left form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.24);
        padding: 2rem;
    }

    .container .left form input {
        padding: 1rem;
        border: none;
        box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.24);
        outline: none;
    }

    .container .left form textarea {
        padding: 1rem;
        border: none;
        box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.24);
        outline: none;
    }

    .container .left form button {
        padding: 1rem;
        background-color: green;
        border: none;
        color: white;
        font-weight: 600;
    }

    .container .right img {
        width: 100%;
    }
}