.container {
    display: flex;
    margin-top: 3rem;
    align-items: stretch;
    /* Make both items stretch to match heights */
    height: auto;
    /* Adjusted height to 60% of viewport height */
    padding: 0 20%;
    /* Adjust padding for centering */
}

.formWrapper,
.imageWrapper {
    flex: 1;
    /* Use flex: 1 to evenly distribute space */
    height: 27rem;
    /* Set height to 100% of parent container */
}

.formWrapper {
    border: 0.1rem solid #000;
    background-color: #fff;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    padding: 1rem;
    /* Padding inside the formWrapper for form spacing */
}


.input {
    margin-top: 2rem;
    width: calc(100% - 2rem);
    /Ensuretheinputisresponsivewithinitsparent/margin-bottom: 1.2rem;
    padding: 1rem;
    border: 0.1rem solid #ccc;
    border-radius: 0.5rem;
    text-align: center;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.button {
    width: 60%;
    padding: 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.signupText {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.1rem;
}

.signupLink {
    color: #ff0000;
    cursor: pointer;
}

.image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    object-fit: cover;
    /* Ensure image covers the container without distortion */
}

.checkboxWrapper {
    display: flex;
    justify-content: center;
    /* Center the checkbox horizontally */
    align-items: center;
    /* Center the checkbox vertically */
    margin-top: 1rem;
}

label {
    margin-left: 0.5rem;
    /* Space between checkbox and label */
    cursor: pointer;
    /* Makes the label clickable */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: auto;
        padding: 0 5%;
        /* Adjust padding for smaller screens */
    }

    .formWrapper,
    .imageWrapper {
        flex: 1 0 auto;
        /* Adjust flex properties for column layout */
        height: auto;
        /* Auto height for column layout */
    }

    .formWrapper {
        border: none;
        border-radius: 0;
        /* Remove rounded corners for mobile */
        margin-bottom: 2rem;
        /* Add margin between sections */
    }

    .image {
        border-radius: 0;
        /* Remove rounded corners for mobile */
    }

    .input {
        margin-top: 20px;
        width: calc(100% - 1rem);
        /* Adjust width for mobile */
        margin-bottom: 20px;
        text-align: center;
        /* Center the placeholder text for mobile */
    }

    .button {
        width: 80%;
        margin-left: 10%;
        /* Center button for mobile */
    }

    .signupText {
        font-size: 12px;
        /* Adjust font size for mobile */
    }
}