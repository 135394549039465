@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.navbar {
    width: 100%;
    height: 5.3rem;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: whitesmoke;
}

.act2 {
    opacity: .65;
    font-size: .5rem;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1250px;
}

.nav-logo {
    cursor: pointer;
}

.nav-logo img {
    width: 10rem;
    height: 6.8rem;
    margin-left: 2rem;
    margin-top: -15px;
    -webkit-filter: drop-shadow(5px 5px 5px #6e6e6e);
    filter: drop-shadow(5px 5px 5px #7e7e7e);
}

.nav-menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    list-style: none;
    text-align: center;
    margin-right: 3rem;
}

.nav-links {
    color: black;
    text-align: left;
    font-size: 1.2rem;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.26px;
    opacity: .75;
    position: relative;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
    margin-top: 1.2rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: rgb(239, 0, 0);
}

.nav-item .active {
    color: orange;
    opacity: 1;
    font-weight: 500;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.26px;
}

.nav-icon {
    display: none;
}

.dropbtn {
    color: #153C5B;
    font-size: 1rem;
    margin-top: -.2rem;
    background: transparent;
    border: 0;
    cursor: pointer;
    opacity: .75;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 13rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border-radius: 0.3rem;
    padding-top: 0;
}

.plus {
    width: 0.8rem;
    margin-left: 0.1rem;
}

.linker {
    text-decoration: none;
}

a {
    color: black;
}

.dropdown-content a {
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
    border-top: 1px solid orangered;
    padding-left: 1rem;
}

.dropdown-content .aref1 {
    border: none;
    color: black;
    text-align: left;
    text-decoration: none;
    display: block;
    padding-left: 1rem;
}

.dropdown-content .aref:hover {
    background-image: linear-gradient(to right, #ba0c01, #ed5c01);
    color: white;
}

.dropdown-content .aref1:hover {
    background-image: linear-gradient(to right, #ba0c01, #ed5c01);
    color: white;
}

.dropdown-content a:hover {
    background-color: black;
    color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.navLogin {
    width: 6.8rem;
    height: 7vh;
    margin-top: 1.2rem;
    font-size: 1.1rem;
    background-color: #f97316;
    border: none;
    border-radius: 0.3rem;
}

.login {
    background: black;
    padding: 0rem 1rem;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}

.login2 {
    margin: auto;
    display: block;
    border: none;
    background-color: white;
    width: 100%;
    height: 2.3rem;
    border-radius: 0.4rem;
}

.login2:hover {
    background-color: rgb(216, 2, 2);
    color: white;
}

.dropbtn {
    color: white;
    background: none;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: none;
}

.dropdown {
    position: relative;
    display: inline-block;
    color: black;
}

.dropdownContent {
    color: black;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content {
    display: none;
    background-color: #f9f9f9;
    min-width: 12rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: rgb(216, 2, 2);
    color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
    text-align: center;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* Center content vertically */
        align-items: center;
        /* Center content horizontally */
        border-top: 2px solid #fff;
        position: absolute;
        top: 80px;
        left: -50%;
        opacity: 1;
        transition: all 0.5s ease;
        width: 50%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background: #fff;
    }

    .nav-menu.active {
        background: whitesmoke;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        width: 50%;
    }

    .nav-links {
        padding: 0;
        width: 100%;
        display: table;
        color: black;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: black;
    }

    .nav-menu.active .nav-links {
        color: black;
        /* Set text color to white when nav-menu is active */
    }
}