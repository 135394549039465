@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');

.container{
    width: 100%;
    padding: 2rem;
}
.container .card {
    width:100%;
    height: 20rem;
    background:url(IMG/frame3.png);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.395);
}
.card h1{
    font-size: 4rem;
    font-weight: 600;
    font-family: "Racing Sans One", sans-serif;
    color: green;
}
.card .discription{
    font-size: 1.1rem;
    font-family: "Racing Sans One", sans-serif;
}
.card .pricetag{
    font-family: "Racing Sans One", sans-serif;
    font-size: 3rem;
    color: green;
}
.card .getoffer{
    padding: .5rem 1rem;
    margin-top: 2rem;
    background-color: green;
    color: white;
    font-weight: 600;
    border-radius: 2rem;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.395);
}
.slick-slider {
    width: 100%;
    height: 100%;
}