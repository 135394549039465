.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
}
.container .top{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    background-color: orange;
}
.container .top .top_left{
    width: 70%;
}
.container .top .top_left .head{
    font-size: 2rem;
    font-weight: 600;
}
.container .top .top_left .price{
    font-size: 2rem;
    font-weight: 600;
}
.container .top .top_right{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container .top .top_right .buynowbtn{
    padding: 1rem;
    background: green;
    width: 10rem;
    color: white;
    font-weight: 600;
}

.container .bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 2rem;
}
.container .bottom h2{
    font-size: 1.5rem;
    font-weight: 600;
}
.container .bottom h3{
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 2rem;
}