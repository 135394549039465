.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55vw;
    height: 10rem;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    width: 55vw;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    height: 30rem;
    overflow: scroll;
    overflow-x: hidden;
}

.popup::-webkit-scrollbar {
    display: none;
}



/* Apply backdrop blur effect */
.popup-container {
    backdrop-filter: blur(5px);
}

.popBtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.popBtn {
    width: 7rem;
    height: 2.2rem;
    background-color: red;
    border-radius: 0.5rem;
    border: none;
    color: white;
}

.popBtn2 {
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 0, 0, 0.84);
    border-radius: 50%;
    border: none;
    color: white;
}

.popBtn:hover {
    box-shadow: 0 4px 8px 0 rgb(206, 206, 206), 0 6px 20px 0 rgb(209, 209, 209);
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .popup-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Semi-transparent background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .popup {
        width: 95vw;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        height: 40rem;
        overflow: scroll;
        overflow-x: hidden;
    }
}