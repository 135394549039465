/* Base styles */
.popup-container {
    position: fixed;
    top: 50;
    left: 50;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 50vw;
}

.popBtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.popBtn,
.popBtn2 {
    width: 7rem;
    height: 2.2rem;
    border-radius: 0.5rem;
    border: none;
    color: white;
    cursor: pointer;
}

.popBtn:hover,
.popBtn2:hover {
    box-shadow: 0 4px 8px 0 rgb(206, 206, 206), 0 6px 20px 0 rgb(209, 209, 209);
}

/* Apply backdrop blur effect */
.popup-container {
    backdrop-filter: blur(5px);
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 600px) {
    .popup {
        width: 90vw;
        height: 55vh;
    }
}