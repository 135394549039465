.container {
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 5rem;
    position: relative;
}

.container .left {
    width: 70%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.left .h1head {
    font-weight: 600;
    font-size: 2rem;
    margin: .5rem 0rem;
}

.container .left .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.382);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 0rem;
    margin-top: 1rem;
}

.container .left .card .L1 {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.container .left .card .L1 .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.boldID {
    font-weight: 600;
}

.statu {
    color: orange;
}

.container .left .card .L1 .row .dish_HIGH {
    padding: .5rem 1rem;
    background-color: rgba(255, 175, 1, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH2 {
    padding: .5rem 1rem;
    background-color: rgba(7, 255, 15, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH3 {
    padding: .5rem 1rem;
    background-color: rgba(0, 213, 255, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH4 {
    padding: .5rem 1rem;
    background-color: rgb(255, 242, 0, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH5 {
    padding: .5rem 1rem;
    background-color: rgb(255, 0, 225, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH6 {
    padding: .5rem 1rem;
    background-color: rgba(255, 0, 72, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}

.container .left .card .L1 .row .dish_HIGH7 {
    padding: .5rem 1rem;
    background-color: rgba(0, 255, 34, 0.38);
    border-radius: 2rem;
    margin-right: .5rem;
}


.container .left .card .R1 img {
    width: 1.5rem;
    cursor: pointer;
}

.container .left .card .R1 {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.container .left .card .R1 .qrow {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.container .left .card .R1 .qrow .qbtn {
    background-color: orange;
    width: 1rem;
}

.price {
    font-weight: 600;
    color: orange;
    text-align: center;
    margin-right: 0rem;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    align-items: center;
}

.btnContainer button {
    background: rgb(255, 119, 0);
    padding: .5rem 1rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
    width: 14rem;
}

.Totalprice {
    font-weight: 500;
    margin-bottom: .5rem;
}

/*right*/
.container .right {
    width: 30%;
    height: 25rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.385);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
}

.container .right .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.container .right .infoContainer p {
    width: 100%;
}

.right .h1head {
    font-size: 2rem;
    font-weight: 600;
}

/*address popup*/
.addresspopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.addresspopup .formContainer {
    width: 30rem;
    background: rgb(250, 186, 114);
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.addresspopup .formContainer label {
    font-size: 1.1rem;
}

.addresspopup .formContainer input {
    padding: .5rem 1rem;
    margin: .5rem 0rem;
}

/* .addresspopup .formContainer button {
    padding: .5rem 1rem;
    background: orangered;
    color: white;
    font-weight: 600;
    margin-top: 1rem;
} */
.addresspopup .formContainer .currentLocationbtn {
    padding: .5rem 1rem;
    background: rgb(198, 217, 252);
    color: black;
    font-weight: 600;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.468);
}

.addresspopup .formContainer .walletbtn {
    padding: .5rem 1rem;
    background: rgb(229, 255, 229);
    color: black;
    font-weight: 600;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.468);
}

.addresspopup .formContainer .walletbtn img {
    width: 1rem;
    position: absolute;
    right: 3rem;
}

.addresspopup .formContainer .onlinebtn {
    padding: .5rem 1rem;
    background: rgb(229, 255, 229);
    color: black;
    font-weight: 600;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.468);
}

.addresspopup .formContainer .onlinebtn img {
    width: 5rem;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    align-items: center;
}

.row input {
    width: 50%;
}

.row select {
    width: 50%;
    outline: none;
    padding: .6rem;
}

/*success popup*/
.successPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.successPopup .subsuccessPopup {
    width: 30rem;
    padding: 2rem;
    text-align: center;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.208);
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successPopup .subsuccessPopup button {
    background-color: red;
    font-weight: 600;
    color: white;
    padding: .5rem 1rem;
    margin-top: 1rem;
}

.succ {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

/*navtab*/
.navtab {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.navtab .cartbtn {
    padding: .5rem 1rem;
    border: 1px solid black;
    width: 10rem;
    background-color: rgb(255, 119, 0);
    font-weight: 500;
}

.navtab .cartbtn2 {
    padding: .5rem 1rem;
    border: 1px solid black;
    width: 10rem;
    background-color: rgba(255, 119, 0, 0.16);
    opacity: .5;
}

.underline {
    background-color: orangered;
    width: 8rem;
    height: .2rem;
    border-radius: 1rem;
}

/*wallet Container*/
.walletContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.walletContainer img {
    width: 5rem;
}

.wallet_balance {
    font-weight: 600;
    margin: 1rem 0rem;
    font-size: 2rem;
}

.wallet_balance2 {
    font-weight: 500;
    margin: .5rem 0rem;
    font-size: 1.2rem;
}

.wallet_btn {
    width: 40%;
    background-color: yellow;
    padding: .4rem 1rem;
    font-weight: 600;
    margin-top: 1rem;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.387);
}

.input_wallet {
    padding: .5rem 1rem;
    width: 40%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.387);
}

.rowtime {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.rowt2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cancel {
    font-weight: 600;
    border: 1px solid black;
    color: black;
    overflow: hidden;
    width: 2rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.cLocation {
    background: rgb(228, 238, 255);
    padding: 1rem;
}

.timelimit {
    background-color: rgba(128, 128, 128, 0.396);
    padding: .5rem;
    color: white;
    font-weight: 400;
}

.addLunch {
    background-color: red;
    padding: .5rem 1rem;
    margin: auto;
    margin-top: 1rem;
    color: white;
    font-weight: 600;
    cursor: pointer;

}

@media screen and (max-width:768px) {
    .container {
        width: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 5rem;
        position: relative;
    }

    .container .left {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }

    .container .left .card {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0rem;
        margin-top: 1rem;
    }

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 1rem;
    }

    .container .right {
        width: 100%;
        height: 25rem;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.385);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
    }
}