/* styles.css */

.terms-and-conditions {
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-size: larger;
}

.terms-and-conditions {
    text-align: left;
    margin-right: 80px;
    margin-left: 80px;
}

.terms-and-conditions h1 {
    background: linear-gradient(to right, darkgreen 40%, orangered 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    animation: gradientAnimation 5s linear infinite alternate;
    /* Additional styling for background */
    background-size: 200% 100%;
    /* Larger size for gradient */
    border-radius: 8px;
    /* Rounded corners */
    padding: 20px;
    /* Padding for better spacing */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Shadow for depth */
}

.terms-and-conditions h2 {
    background: linear-gradient(to right, darkgreen 50%, orangered 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 80px;
    margin-left: 80px;
    animation: gradientAnimation 5s linear infinite alternate;
    /* Additional styling for background */
    background-size: 200% 100%;
    /* Larger size for gradient */
    border-radius: 8px;
    /* Rounded corners */
    padding: 15px;
    /* Padding for better spacing */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Shadow for depth */
}

.terms-and-conditions p {
    text-align: justify;
    margin-right: 120px;
    margin-left: 120px;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}