.menu {
    background-color: #04AA6D;
    color: white;
    padding: 13px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.drop {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdownContent a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdownContent {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}