.container {
    display: flex;
    margin-top: 3rem;
    align-items: stretch;
    height: auto;
    padding: 0 20%;
}

.formWrapper,
.imageWrapper {
    flex: 1;
    height: 28rem;
}

.formWrapper {
    border: 2px solid #000;
    background-color: #fff;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

.input {
    margin-top: 1rem;
    width: calc(100% - 2rem);
    margin-bottom: 0.5rem;
    padding: 0rem;
    border: 0.1rem solid #ccc;
    border-radius: 0.5rem;
    margin-left: 1rem;
    text-align: center;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.button {
    width: 60%;
    padding: 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 0 auto;
    display: block;
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
}

.signupText {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
}

.signupLink {
    color: #ff0000;
    cursor: pointer;
}

.image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    object-fit: cover;
}

.checkboxLabel {
    text-align: center;
    margin-top: 1rem;
    display: block;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        height: auto;
        padding: 0 5%;
    }

    .formWrapper,
    .imageWrapper {
        flex: 1 0 auto;
        height: auto;
    }

    .formWrapper {
        border: none;
        border-radius: 0;
        margin-bottom: 2rem;
    }

    .image {
        border-radius: 0;
    }

    .input {
        margin-top: 20px;
        width: calc(100% - 1rem);
        margin-bottom: 20px;
        margin-left: 0.5rem;
        text-align: center;
    }

    .button {
        width: 80%;
        margin-left: 10%;
    }

    .signupText {
        font-size: 12px;
    }

    .checkboxLabel {
        text-align: center;
        margin-top: 10px;
        display: block;
    }
}