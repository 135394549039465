@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');


/* rgba(255, 166, 0, 0.16) */
.container {

    background: linear-gradient(130deg, rgba(0, 128, 0, 0.192) 60%, #FF7C00 10%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5.2rem;
    width: 100%;
    height: 90vh;
    overflow: hidden;
}

.hold1 {}



.head {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.74);


}

.outline {
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 6rem;
    font-weight: 900;
    color: black;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FF7C00;
    text-shadow: 2px 2px #FF0000;
}

.spoonHold {
    display: flex;
    flex-direction: row;
}

.spoon {
    margin-top: 2rem;
    width: 5rem;
    height: 5rem;
}

.scooter {
    width: 21rem;
    height: 11rem;
    margin-top: -3rem;
    margin-left: -1rem;

    -webkit-filter: drop-shadow(5px 5px 5px #6e6e6eab);
    filter: drop-shadow(5px 5px 5px #7e7e7eb9);
}

@media screen and (max-width:768px) {
    .container {

        background: linear-gradient(130deg, rgba(0, 128, 0, 0.192) 60%, #FF7C00 10%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 5.2rem;
        width: 100%;
        height: 90vh;
        overflow: hidden;
    }

    .outline {
        margin-left: 1rem;
        font-family: "Poppins", sans-serif;
        text-align: center;
        font-size: 2rem;
        font-weight: 900;
        color: black;
        -webkit-text-fill-color: white;
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #FF7C00;
        text-shadow: 2px 2px #FF0000;
    }

    .spoonHold {
        display: flex;
        flex-direction: row;

    }

    .spoon {
        margin-top: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
    }

    .scooter {
        width: 7rem;
        height: 5rem;
        margin-top: -2rem;
        margin-left: 1rem;

        -webkit-filter: drop-shadow(5px 5px 5px #6e6e6eab);
        filter: drop-shadow(5px 5px 5px #7e7e7eb9);
    }
}