.mainContainer {
    width: 100%;
    padding: 1rem;
    margin-top: 5rem;
}

.mainContainer h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.tableContainer {
    width: 100%;
    border: 1px solid black;
}

.tableContainer tr,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

.tableContainer td {
    text-align: center;
}