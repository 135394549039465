.container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.popupContainer{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% ,-50%);
    width: 30rem;
    height: 15rem;
    border:1px solid black;
    background: rgb(250, 210, 135);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}
.popupContainer .btnContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    margin: 1rem;
}
.popupContainer .close{
    padding: .5rem 1rem;
    font-weight: 500;
    background-color: green;
    color: white;
    width: 10rem;

}
.popupContainer .yes{
    padding: .5rem 1rem;
    font-weight: 500;
    background-color: red;
    color: white;
    width: 10rem;

}
.hed{
    font-size: 2rem;
    font-weight: 600;
}