.meal-type-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.meal-type-label:hover {
    background-color: #ddd;
}

.meal-type-label.active {
    background-color: #ccc;
    color: #fff;
    border-color: #aaa;
}



.Veg {
    appearance: none;
    padding: 10px 30px 10px 10px;
    text-align: right;
    margin-top: 1rem;
    width: 8rem;
    cursor: pointer;
    content: "";
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-image: url("./asset/veg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    border: 1px solid orange;
    border-radius: 4px;



}

.Nonveg {
    appearance: none;
    padding: 10px 20px 10px 10px;
    text-align: right;
    font-weight: bold;
    width: 10rem;
    cursor: pointer;
    content: "";
    margin-top: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/non\ veg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

}





input[type="radio"]:checked {
    border: 3px dotted green;
    align-self: flex-start;
}


.Veg::before {
    content: "VEG";

}

.Nonveg::before {
    content: "NON-VEG";
}

.dl1 {
    gap: 5rem
}




.Vangimasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;

    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/bharli-vangi-stuffed-brinjal.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: bold;

}

.Vangimasala::before {
    content: "VANGI-MASALA";
    font-size: 0.7rem;

}




.Matarmasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;

    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/Matar-Masala-Marathi.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: bold;

}

.Matarmasala::before {
    content: "MATAR-MASALA";
    font-size: 0.7rem;

}


.SHEVBHAJI {
    appearance: none;
    padding: 0.2rem 1.5rem 0rem 0.2rem;
    text-align: right;
    margin-top: 0.5rem;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";

    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/Shev\ Bhaji.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: bold;

}

.SHEVBHAJI::before {
    content: "SHEVBHAJI";
    font-size: 0.7rem;

}




.MATARPANEER {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;

    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/Matar-Paneer.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: bold;

}

.MATARPANEER::before {
    content: "MATAR PANEER";
    font-size: 0.7rem;

}




.PDOPY {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;

    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;

    border: 1px solid orange;
    border-radius: 4px;

    background-image: url("./asset/Panner\ Do\ Pyaja.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    font-weight: bold;

}

.PDOPY::before {
    content: "PANEER DO PYAJA";
    font-size: 0.7rem;

}



.KP {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Kadai-Paneer.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.KP::before {
    content: "KADHAI PANEER";
    font-size: 0.7rem;

}



.Palakpaneer {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/palak-paneer.webp");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Palakpaneer::before {
    content: "PALAK PANEER";
    font-size: 0.7rem;

}




.Bhendifry {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Bhindi-fry.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Bhendifry::before {
    content: "BHINDI FRY";
    font-size: 0.7rem;

}


.BharaliVangi {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/bharli-vangi-stuffed-brinjal.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.BharaliVangi::before {
    content: "BHARALI VANGI";
    font-size: 0.7rem;

}



.Rajmamasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Rajma-Masala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Rajmamasala::before {
    content: "RAJMA MASALA";
    font-size: 0.7rem;

}

.Pithala {
    appearance: none;
    padding: 0.2rem 30px 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Pithal.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Pithala::before {
    content: "PITHALA";
    font-size: 0.7rem;

}


.blackchana {
    appearance: none;
    padding: 0.2rem 30px 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/kala-chana.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.blackchana::before {
    content: "BLACK CHANA";
    font-size: 0.7rem;

}


.chavalimasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Chavali\ Masala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.chavalimasala::before {
    content: "CHAVALI MASALA";
    font-size: 0.7rem;

}



.matakimasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Mataki\ Bhaji.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.matakimasala::before {
    content: "MATAKI MASALA";
    font-size: 0.7rem;

}


.Dalfry {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/bharli-vangi-stuffed-brinjal.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Dalfry::before {
    content: "BHARALI VANGI";
    font-size: 0.7rem;

}


.Aloojeera {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/AlooJeera.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Aloojeera::before {
    content: "ALOO JEERA";
    font-size: 0.7rem;

}


.aloomuttar {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Aloo\ Matar\ Tomato.webp");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.aloomuttar::before {
    content: "ALOO MUTTAR";
    font-size: 0.7rem;

}


.almut {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Aloo\ Matar\ Tomato.webp");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.almut::before {
    content: "ALOO MATTAR TOMATO";
    font-size: 0.5rem;

}


.chanamasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/chana-masala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.chanamasala::before {
    content: "CHANA MASALA";
    font-size: 0.7rem;

}


.bhindimasala {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Bhindi-Masala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.bhindimasala::before {
    content: "BHINDI MASALA";
    font-size: 0.7rem;

}


.mixveg {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/mix-veg-curry.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.mixveg::before {
    content: "MIX VEG";
    font-size: 0.7rem;

}


.Roti {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/puri.jpeg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Roti::before {
    content: "PURI  (6)";
    font-size: 0.7rem;

}



.Chapati {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 9rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Poli.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;


}

.Chapati::before {
    content: "CHAPATI (3) ";
    font-size: 0.7rem;

}

.Bhakari {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 9rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Bhakari.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.Bhakari::before {
    content: "BHAKARI (2)";
    font-size: 0.7rem;

}


.dalbhat {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Dal-Bhat.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.dalbhat::before {
    content: "DAL BHAT";
    font-size: 0.7rem;

}


.dalcha {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Dalcha.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.dalcha::before {
    content: "DALCHA";
    font-size: 0.7rem;

}


.pulav {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Pulav.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.pulav::before {
    content: "PULAV";
    font-size: 0.7rem;

}

.ricegravy {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 10.8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Biryani.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.ricegravy::before {
    content: "RICE + GRAVY";
    font-size: 0.7rem;

}




.gulabjam {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Gulabjam.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.gulabjam::before {
    content: "GULABJAM";
    font-size: 0.7rem;

}


.kheer {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Kheer.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.kheer::before {
    content: "KHEER";
    font-size: 0.7rem;

}


.psh {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 10rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Pineapple\ Shiira.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.psh::before {
    content: "PINEAPPLE SHIRA";
    font-size: 0.6rem;

}


.shira {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 8rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/shira.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.shira::before {
    content: "SHIRA";
    font-size: 0.7rem;

}




.chim {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Chicken\ Masala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.chim::before {
    content: "CHICKEN MASALA";
    font-size: 0.7rem;

}


.chisu {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Chicken\ Sukkha.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.chisu::before {
    content: "CHICKEN SUKKHA";
    font-size: 0.7rem;

}

.chigh {
    appearance: none;
    padding: 0.2rem 1rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Chicken\ Gharwala.jpg");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;

}

.chigh::before {
    content: "CHICKEN GHARWALA";
    font-size: 0.7rem;

}

.egm {
    appearance: none;
    padding: 0.2rem 1.8rem 0rem 0.2rem;
    text-align: right;
    width: 17.5rem;
    height: 2rem;
    cursor: pointer;
    content: "";
    border: 1px solid #ddd;
    border-radius: 4px;
    border: 1px solid orange;
    border-radius: 4px;
    background-image: url("./asset/Egg\ Masala.avif");
    background-size: 3.5REM;
    background-repeat: no-repeat;
    background-position: left center;
    margin-top: 0.5rem;
    font-weight: bold;
    font-weight: bold;


}

.egm::before {
    content: "EGG MASALA";
    font-size: 0.7rem;

}











.jkd {
    text-align: center;
    height: 29rem;
    overflow: scroll;
    padding-bottom: 5rem;
    ;
}

.jkd::-webkit-scrollbar {
    display: none;
}

input[type="checkbox"]:checked {
    border: 2px dotted green;
    color: green;
    font-weight: bold;
}

.fsbcb {
    position: fixed;
    top: 47vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-top: 2rem;
    place-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18rem;

}

.dse {
    margin-left: -3.5rem;
}

.de {
    margin-top: -1.3rem;
    font-size: 0.7rem;
    width: auto;
    text-align: left;
    color: gray
}

.dekr {
    padding: 0.5rem;
    border: 1px solid orangered;
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    ;
    height: 7rem;
    width: 19rem;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;

}

@media screen and (max-width:768px) {
    .dse {
        margin-left: 0rem;
    }

    .fsbcb {
        position: fixed;
        top: 47vh;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        margin-top: 2rem;
        place-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5rem;

    }
}